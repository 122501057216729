import React from 'react';
import * as styles from './ImageContainer.module.scss';

type SrcSetType = {
  _2x?: string;
  _3x?: string;
};

type ImageContainerProps = {
  src: string;
  width?: string;
  height?: string;
  lazy?: boolean;
  srcSet?: SrcSetType;
  alt?: string;
};

const ImageContainer = ({
  src,
  width,
  height,
  lazy,
  srcSet,
  alt,
}: ImageContainerProps): React.ReactElement => {
  const srcSetValue = srcSet
    ? Object.keys(srcSet)
        .map((srcSetKey) => {
          return `${srcSet[srcSetKey as keyof SrcSetType]} ${srcSetKey.replace(
            '_',
            ''
          )}`;
        })
        .join(',')
    : undefined;

  return (
    <img
      alt={alt || ''}
      className={styles.wrapper}
      height={height || 'auto'}
      loading={lazy ? 'lazy' : 'eager'}
      src={src}
      srcSet={srcSetValue}
      width={width || 'auto'}
    />
  );
};

export default ImageContainer;
