import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import { PARTNER_NET_NUT_PROXY_NETWORK_ROUTE, SIGN_UP_ROUTE } from '../../const/routes.const';
import {
  NET_NUT_PROXY_NETWORK_DISCOUNT,
  NET_NUT_PROXY_NETWORK_NAME,
  NET_NUT_PROXY_NETWORK_PROMO_CODE,
  NET_NUT_PROXY_NETWORK_REF,
  NET_NUT_PROXY_NETWORK_REF_KEY,
} from '../../const/parnters.const';
import PartnerLayout from '../../components/Partners/PartnerLayout';
import netnut_mobile from '../../assets/images/partners/netnut_mobile.png';
import netnut_mobile2x from '../../assets/images/partners/netnut_mobile_2x.png';
import netnut_desktop from '../../assets/images/partners/netnut_desktop.png';
import netnut_desktop2x from '../../assets/images/partners/netnut_desktop_2x.png';
import ImageContainer from '../../components/ImageContainer/ImageContainer';
import BlueSpan from '../../components/common/typography/BlueSpan';
import PartnerDiscount from '../../components/Partners/PartnerDiscount';
import LinkWrapper from '../../components/common/LinkWrapper';

const FULL_URL = `${PARTNER_NET_NUT_PROXY_NETWORK_ROUTE}?${NET_NUT_PROXY_NETWORK_REF_KEY}=${NET_NUT_PROXY_NETWORK_REF}`;

const NetNutProxyNetworkPage = (): React.ReactElement => {
  return (
    <PartnerLayout
      imgDesktop={
        <ImageContainer src={netnut_desktop} srcSet={{ _2x: netnut_desktop2x }} />
      }
      imgMobile={<ImageContainer src={netnut_mobile} srcSet={{ _2x: netnut_mobile2x }} />}
      title={NET_NUT_PROXY_NETWORK_NAME}
      href={FULL_URL}
      description={
        <>
          <p>
            <Trans i18nKey="NetNut is a premium proxy provider, offering datacenter and residential IPs for clients that need to gather data, conduct business intelligence and other marketing activities, as well as manage their E-commerce businesses. Our p2p IPs support such use cases as SEO monitoring, SERP scraping, account management, etc. Our premium proxy network works for all use cases smoothly and flawlessly.">NetNut is a <strong>premium proxy provider</strong>, offering <strong>datacenter and residential IPs</strong> for clients that need to gather data, conduct business intelligence and other marketing activities, as well as manage their E-commerce businesses. Our p2p IPs support such use cases as SEO monitoring, SERP scraping, account management, etc. Our premium proxy network works for all use cases smoothly and flawlessly.</Trans>
          </p>
          <p>
            <Trans>NetNut takes pride in building a cutting-edge residential proxy network, ensuring unmatched speed and stability for any data collection project. NetNut’s architecture is unique in its ability to provide residential IPs with one-hop ISP connectivity. Our residential proxy network transparently performs load balancing to connect you to any destination URL, ensuring complete anonymity and high speed.</Trans>
          </p>
          <h3>
            <Trans i18nKey="Offer for Octo Browser users">
              Offer for <BlueSpan>Octo Browser users</BlueSpan>
            </Trans>
          </h3>
          <p>
            <Trans i18nKey="This offer is valid for Octo Browser users only. Create an account to claim your discount. If you already use Octo Browser, go to the partner's website, sign up, and claim your offer.">
              This offer is valid for Octo Browser users only. <LinkWrapper url={SIGN_UP_ROUTE}>Create an account</LinkWrapper> to claim your discount. If you already use Octo Browser, go to the partner's website, sign up, and
              claim your offer.
            </Trans>
          </p>
          <PartnerDiscount
            discount={NET_NUT_PROXY_NETWORK_DISCOUNT}
            href={FULL_URL}
            description={
              <Trans
                i18nKey="partner.netnut.proxy.promo.code.discount"
                tOptions={{ discount: NET_NUT_PROXY_NETWORK_DISCOUNT, promoCode: NET_NUT_PROXY_NETWORK_PROMO_CODE }}
              ><strong>{NET_NUT_PROXY_NETWORK_PROMO_CODE}</strong>.
              </Trans>
            }
          />
          <h3>
            <Trans>NetNut key solutions and advantages:</Trans>
          </h3>
          <p><Trans>NetNut provides four proxy networks: datacenter proxies, mobile proxies, rotating residential proxies, and its signature static residential proxies.</Trans></p>
          <h4>
            <Trans>NetNut Rotating Residential Proxy:</Trans>
          </h4>
          <ul>
            <li>
              <Trans>Global network with 20M+ IPs spread across 170+ countries</Trans>
            </li>
            <li>
              <Trans>Unique Hybrid Network combining P2P & ISP proxies</Trans>
            </li>
            <li>
              <Trans>Unlimited concurrent requests</Trans>
            </li>
            <li>
              <Trans>Supports both rotating and sticky sessions</Trans>
            </li>
            <li>
              <Trans>No domain restrictions</Trans>
            </li>
            <li>
              <Trans>99.9% uptime, 24/7 IP availability</Trans>
            </li>
            <li>
              <Trans>20% discount for Octo browser users!</Trans>
            </li>
          </ul>
          <h4>
            <Trans>NetNut Static Residential Proxy:</Trans>
          </h4>

          <ul>
            <li>
              <Trans>Direct ISP connectivity provides faster speed and better stability</Trans>
            </li>
            <li>
              <Trans>Keep sessions as long as you need</Trans>
            </li>
            <li>
              <Trans>99.9% uptime, 24/7 IP availability</Trans>
            </li>
            <li>
              <Trans>Fastest response time</Trans>
            </li>
            <li>
              <Trans>No dependency on end-user devices</Trans>
            </li>
            <li>
              <Trans>20% discount for Octo browser users!</Trans>
            </li>
          </ul>
          <h4>
            <Trans>NetNut Datacenter Proxy:</Trans>
          </h4>
          <ul>
            <li>
              <Trans>110K+ lightning-fast datacenter IPs</Trans>
            </li>
            <li>
              <Trans>Unlimited concurrency</Trans>
            </li>
            <li>
              <Trans>Rotating and Static features available</Trans>
            </li>
            <li>
              <Trans>Wide variety of Subnets</Trans>
            </li>
            <li>
              <Trans>An effective budget-friendly solution</Trans>
            </li>
            <li>
              <Trans>20% discount for Octo browser users!</Trans>
            </li>
          </ul>
          <h4>
            <Trans>NetNut Mobile Proxy:</Trans>
          </h4>
          <ul>
            <li>
              <Trans>1 Million+ of real mobile device proxy IPs</Trans>
            </li>
            <li>
              <Trans>Avoid the possibilities of captcha and IP being blocked</Trans>
            </li>
            <li>
              <Trans>3G and 4G mobile network</Trans>
            </li>
            <li>
              <Trans>Ultra-fast connection for maximum anonymity</Trans>
            </li>
            <li>
              <Trans>20% discount for Octo browser users!</Trans>
            </li>
          </ul>
        </>
      }
    />
  );
};

export default NetNutProxyNetworkPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["netnutPage", "translation"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
