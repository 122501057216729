// extracted by mini-css-extract-plugin
export var antBtnLg = "PartnerLayout-module--ant-btn-lg--CgQPV";
export var antBtnPrimary = "PartnerLayout-module--ant-btn-primary--RYsYd";
export var antFormItem = "PartnerLayout-module--ant-form-item--nYMt8";
export var antFormItemLabel = "PartnerLayout-module--ant-form-item-label--NG6R+";
export var antInput = "PartnerLayout-module--ant-input--No3bu";
export var antMessageNotice = "PartnerLayout-module--ant-message-notice--C3BXZ";
export var antMessageNoticeContent = "PartnerLayout-module--ant-message-notice-content--Sj3lJ";
export var button = "PartnerLayout-module--button--mMdZJ";
export var desktopImg = "PartnerLayout-module--desktopImg--NpbRR";
export var errorMessage = "PartnerLayout-module--errorMessage--rys4i";
export var img = "PartnerLayout-module--img--lVK+H";
export var mobileImg = "PartnerLayout-module--mobileImg--lMbc7";
export var title = "PartnerLayout-module--title--ea0BH";
export var wrapper = "PartnerLayout-module--wrapper--0A7O5";