// extracted by mini-css-extract-plugin
export var antBtnLg = "PartnerDiscount-module--ant-btn-lg--LDltt";
export var antBtnPrimary = "PartnerDiscount-module--ant-btn-primary--YA3KZ";
export var antFormItem = "PartnerDiscount-module--ant-form-item--yHelJ";
export var antFormItemLabel = "PartnerDiscount-module--ant-form-item-label--bgwWH";
export var antInput = "PartnerDiscount-module--ant-input--AEj7s";
export var antMessageNotice = "PartnerDiscount-module--ant-message-notice--EES1M";
export var antMessageNoticeContent = "PartnerDiscount-module--ant-message-notice-content--++Wqw";
export var button = "PartnerDiscount-module--button--kyIkW";
export var description = "PartnerDiscount-module--description--HItiI";
export var errorMessage = "PartnerDiscount-module--errorMessage--d5wkD";
export var value = "PartnerDiscount-module--value--nucbI";
export var wrapper = "PartnerDiscount-module--wrapper--gLoU9";