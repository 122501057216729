import React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import Button from '../Button/Button';
import { ButtonSize } from '../../const/button.conts';
import * as styles from './PartnerDiscount.module.scss';

type PartnerDiscountProps = {
  discount: string;
  description: React.ReactNode;
  href: string;
};
const PartnerDiscount = ({ discount, href, description }: PartnerDiscountProps): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.value}>{discount}%</div>
      <div className={styles.description}><div>{description}</div></div>
      <div className={styles.button}>
        <Button
          externalLink
          size={ButtonSize.small}
          label={
            <>
              <Trans>Go to site</Trans> →
            </>
          }
          link={href}
          target={"_blank"}
        />
      </div>
    </div>
  );
};

export default PartnerDiscount;
