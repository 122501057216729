export const ASTRO_PROXY_NAME = 'AstroProxy';
export const ASTRO_PROXY_DISCOUNT = '10';
export const ASTRO_PROXY_PROMO_CODE = 'OCTOBROWSER';
export const NET_NUT_PROXY_NETWORK_NAME = 'NetNut Proxy Network';
export const NET_NUT_PROXY_NETWORK_DISCOUNT = '20';
export const NET_NUT_PROXY_NETWORK_PROMO_CODE = 'OCTO20';
export const I_PROXY_ONLINE_NAME = 'iProxy.online';
export const I_PROXY_ONLINE_DISCOUNT = '15';
export const I_PROXY_ONLINE_PROMO_CODE = 'OCTOBROWSER';
export const NET_NUT_PROXY_NETWORK_REF = 'octobrowser';
export const NET_NUT_PROXY_NETWORK_REF_KEY = 'ref';
export const KEITARO_NAME = 'Keitaro';
export const KEITARO_DISCOUNT = '20';
export const KEITARO_PROMO_CODE = 'OCTOBROWSER';
export const SCENUM_NAME = 'Scenum';
export const SCENUM_DISCOUNT = '50';
export const SCENUM_PROMO_CODE = 'OCTO';
export const SCENUM_REF = 'octobrowser.net';
export const SCENUM_REF_KEY = 'utm_referrer';
