// extracted by mini-css-extract-plugin
export var antBtnLg = "DownBlock-module--ant-btn-lg--ODucH";
export var antBtnPrimary = "DownBlock-module--ant-btn-primary--T7wcB";
export var antFormItem = "DownBlock-module--ant-form-item--vfYqG";
export var antFormItemLabel = "DownBlock-module--ant-form-item-label--uLh+X";
export var antInput = "DownBlock-module--ant-input--Wiiwu";
export var antMessageNotice = "DownBlock-module--ant-message-notice--zC20N";
export var antMessageNoticeContent = "DownBlock-module--ant-message-notice-content--uLhns";
export var button = "DownBlock-module--button--a2CdM";
export var chatHint = "DownBlock-module--chatHint--kOqF7";
export var errorMessage = "DownBlock-module--errorMessage--KBeet";
export var img = "DownBlock-module--img--4KBfb";
export var radialGradient = "DownBlock-module--radialGradient--hhTt8";
export var subTitle = "DownBlock-module--subTitle--4o8Zp";
export var title = "DownBlock-module--title--4oFal";
export var wrapper = "DownBlock-module--wrapper--ZW7Xh";
export var wrapperInner = "DownBlock-module--wrapperInner--PbZLO";