const AppRating = (): JSX.Element => (
  <script
    dangerouslySetInnerHTML={{
      __html: `
    {
            "@context": "https://schema.org",
            "@type": "SoftwareApplication",
            "name": "Octo Browser",
            "operatingSystem": "MacOS, Windows",
            "applicationCategory": "BrowserApplication"
    }`,
    }}
    type="application/ld+json"
  />
);

export default AppRating;

// "aggregateRating": {
//     "@type": "AggregateRating",
//         "ratingValue": "4.9",
//         "ratingCount": "8972"
// }
