import React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import ContentWrap from '../ContentWrap/ContentWrap';
import { SIGN_UP_ROUTE, SUPPORT_ROUTE } from '../../const/routes.const';
import octoDownloadBlockSrc from '../../assets/images/download_block.png';
import octoDownloadBlockSrc2x from '../../assets/images/download_block2x.png';
import ImageContainer from '../ImageContainer/ImageContainer';
import Button from '../Button/Button';
import { ButtonColor } from '../../const/button.conts';
import * as styles from './DownBlock.module.scss';

const DownBlock = (): React.ReactElement => (
  <div className={styles.wrapper}>
    <ContentWrap>
      <div className={styles.wrapperInner}>
        <h2 className={styles.title}>
          <Trans>Присоединяйтесь к Octo Browser сейчас</Trans>
        </h2>
        <div className={styles.subTitle}>
          <Trans i18nKey="Or contact the support team in chat for any questions, at any time.">
            Or contact the support team
            <a className={styles.chatHint} href={SUPPORT_ROUTE} rel="nofollow">
              in chat
            </a>
            for any questions, at any time.
          </Trans>
        </div>
        <div className={styles.button}>
          <Button
            color={ButtonColor.white}
            label={<Trans>Create Octo Browser account</Trans>}
            link={SIGN_UP_ROUTE}
          />
        </div>
        <div className={styles.img}>
          <ImageContainer
            height="228px"
            src={octoDownloadBlockSrc}
            srcSet={{ _2x: octoDownloadBlockSrc2x }}
            width="179px"
          />
        </div>
      </div>
    </ContentWrap>
    <div className={styles.radialGradient} />
  </div>
);

export default DownBlock;
