import React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import DownBlock from '../DownBlock/DownBlock';
import * as styles from './PartnerLayout.module.scss';
import ContentWrap from '../ContentWrap/ContentWrap';
import AppRating from '../AppRating';
import Layout from '../../layouts';
import Button from '../Button/Button';
import { ButtonSize } from '../../const/button.conts';

type PartnerLayoutProps = {
  imgDesktop: React.ReactNode;
  imgMobile: React.ReactNode;
  title: string;
  href: string;
  description: React.ReactNode;
};
const PartnerLayout = ({
  imgDesktop,
  imgMobile,
  title,
  href,
  description,
}: PartnerLayoutProps): JSX.Element => (
  <Layout>
    <AppRating />
    <div className={styles.wrapper}>
      <ContentWrap>
        <div className={styles.img}>
          <div className={styles.desktopImg}>{imgDesktop}</div>
          <div className={styles.mobileImg}>{imgMobile}</div>
        </div>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.description}>{description}</div>
        <div className={styles.button}>
          <Button
            externalLink
            size={ButtonSize.small}
            label={
              <>
                <Trans i18nKey="go.to" tOptions={{ name: title }}>
                  Go to
                </Trans>{' '}
                →
              </>
            }
            link={href}
            target="_blank"
          />
        </div>
      </ContentWrap>
    </div>
    <DownBlock />
  </Layout>
);

export default PartnerLayout;
